<template>
  <div class="product">
    <gSwiperCommon :bannerType="'show.page.products.pc.en'" :bannerHeight="400"></gSwiperCommon>
    <div class="exhibitor">
      <categoryRegionSelect @cateChange="cateChange" @regionChange="regionChange"></categoryRegionSelect>
      <div style="width:100%">
        <productList :isList="true" ref="productList" :loading="loading" :finished="finished" @moreClick="moreClick"></productList>
        <div class="my-page" v-if="totalCount > pageSize">
          <Page :current="pageIndex" :page-size="pageSize" :total="totalCount" @on-change="changePage" @on-page-size-change="changePageSize" show-elevator show-total />
        </div>
        <div class="u-empty" v-if="loading">{{ $t("lang.loading") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import gSwiper from "@components/gSwiper/gSwiper.vue";
import categoryRegionSelect from "@components/categoryRegionSelect/categoryRegionSelect.vue";
import productList from "@components/productList/index.vue";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "product",
  data() {
    return {
      bannerList: [
        "https://images.unsplash.com/photo-1602809096240-0c89916a2676?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixlib=rb-1.2.1&q=80&w=1920",
        "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1240888316,1729450859&fm=26&gp=0.jpg",
      ],
      dataList: [],
      finished: false,
      loading: true,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      options: {
        categories: [],
        regions: "",
      },
      cateArr: [],
      regionArr: [],
    };
  },
  components: {
    gSwiper,
    gSwiperCommon,
    categoryRegionSelect,
    productList,
  },
  computed: {
    ...mapGetters({
      searchType: "getSearchType",
      searchTxt: "getSearchTxt",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      productPage:'getProductPage',
      cateArrVuex:'getCateArrVuex',
      regionArrNameVuex:'getRegionArrNameVuex',
    }),
  },
  watch: {
    searchTxt(val, oldVal) {
      this.$store.commit('setProductPage',1)
      this.pageIndex=1;
      this.init();
    },
  },
  created() {
    if(this.productPage){
      this.pageIndex=this.productPage;
    }
    if(this.cateArrVuex&&this.cateArrVuex.length){
      this.cateArr=JSON.parse(JSON.stringify(this.cateArrVuex));
      this.cateArr.forEach((e) => {
        this.options.categories.push(e.name);
      });
    }
    if(this.regionArrNameVuex&&this.regionArrNameVuex.length){
      this.regionArr=JSON.parse(JSON.stringify(this.regionArrNameVuex)) 
      this.options.regions =this.regionArr
    }
    this.init();
    document.title = this.exhibitionInfo ? this.exhibitionInfo.nameEn : "ExShow";
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      getShareConfig: "share/getShareConfig",
    }),
    init() {
      this.setShare();
      this.getProList();
    },
    async setShare() {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: "share.exhibition.config" });
      let share = {
        url: location.href,
        title: "展品列表-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    //类别改变
    cateChange(arr) {
      this.cateArr = arr;
      this.conditionChange();
    },
    conditionChange() {
      this.options = {
        categories: [],
        regions: "",
      };
      if (this.cateArr.length) {
        this.cateArr.forEach((e) => {
          this.options.categories.push(e.name);
        });
      }
      if (this.regionArr.length) {
        this.options.regions = this.regionArr;
      }
      this.$store.commit('setProductPage',1)
      this.pageIndex = 1;
      this.finished = false;
      this.getProList();
    },
    //地区改变
    regionChange(arr) {
      this.regionArr = arr;
      this.$store.commit('setRegionArrNameVuex',arr)
      this.conditionChange();
    },
    changePage(e) {
      this.$store.commit('setProductPage',e)
      this.pageIndex = e;
      this.getProList();
    },
    changePageSize(e) {
      this.pageSize = e;
    },
    async getProList() {
      this.loading = true;
      let query = `
        query($query:QueryProductInput!){
          productQuery{
            queryCustom(query:$query){
              hasNextPage
              pageIndex
              totalCount
              items{
                id
                name
                banner
                categoryEn
                exhibitorId
                isPopularize
                panoramaUrl
                exhibitor {
                  businesses(num: 3) {
                    isSample
                    remark
                  }
                  isLiving
                  category
                  boothInfo
                  company {
                    id
                    name
                    nameEn
                    logo
                    region
                    shortName
                    shortNameEn
                    countryEn
                    provinceEn
                    cityEn
                  }
                }
                likes{
                  userInfo
                }
                likeCount
                selling
                newestEvaluates{
                  content
                }
                video
                view3DUrl
                topicStart
                topicEnd
                topicType
                region
              }
            }
          }
        }
      `;
      let opt = {
        query: query,
        variables: {
          query: {
            name: this.searchType === "2" ? this.searchTxt : "",
            exhibitionId: this.exhibitionId,
            page: this.pageIndex,
            size: this.pageSize,
            ...this.options,
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.productQuery.queryCustom));
      if (!res) return;
      res.items.map((c) => {
        if (c.categoryEn && c.categoryEn.indexOf("[") !== -1) {
          if (JSON.parse(c.categoryEn)[0]) {
            c.categoryEn = JSON.parse(c.categoryEn)[0].Value;
          } else {
            c.categoryEn = "";
          }
        }
        if (c.likes && c.likes.length) {
          c.likes.forEach((e) => {
            e.userInfo = JSON.parse(e.userInfo);
          });
        }
        if (c.exhibitor.company) {
          c.exhibitor.company.regionTxt = `${c.exhibitor.company.countryEn ? c.exhibitor.company.countryEn + "." : ""}${c.exhibitor.company.provinceEn ? c.exhibitor.company.provinceEn : ""}${
            c.exhibitor.company.cityEn ? "." + c.exhibitor.company.cityEn : ""
          }`;
        }
      });
      this.dataList = res.items;
      this.loading = false;
      this.totalCount=res.totalCount;
      if (!res.hasNextPage) {
        this.finished = true;
      }
      this.$nextTick(() => {
        this.$refs.productList.setData(this.dataList);
      });
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getProList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.product {
  .exhibitor {
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    .my-page{
      width: 100%;
      flex-shrink: 0;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
